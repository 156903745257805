import axios from "axios";

const influencers = (accountId, integrationId, sortBy = 'status') => {
  const url = `/accounts/${accountId}/integrations/hype_auditor/${integrationId}/influencers`;
  console.log(`GET ${url}`);
  return axios.get(url, { params: { sort_by: sortBy } });
};

const deleteInfluencers = (accountId, integrationId, usernames) => {
  const ids = usernames.join(",");
  const url = `/accounts/${accountId}/integrations/hype_auditor/${integrationId}/influencers/${ids}`;
  console.log(`DELETE ${url}`);
  return axios.delete(url);
};

const updateReport = (accountId, integrationId, username) => {
  const id = username;
  const url = `/accounts/${accountId}/integrations/hype_auditor/${integrationId}/influencers/${id}`;
  return axios.patch(url);
};

const updateReports = (accountId, integrationId, username) => {
  const id = username;
  const url = `/accounts/${accountId}/integrations/hype_auditor/${integrationId}/influencers/update_reports`;
  console.log(`PATCH ${url}`);
  return axios.post(url);
};

const addInfluencers = (
  accountId,
  integrationId,
  usernames,
  requestReports
) => {
  const url = `/accounts/${accountId}/integrations/hype_auditor/${integrationId}/influencers`;
  console.log(`POST ${url}`);
  return axios.post(url, { usernames, request_reports: requestReports });
};

export default {
  influencers,
  addInfluencers,
  deleteInfluencers,
  updateReport,
  updateReports,
};
