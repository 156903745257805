import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.changeEvent = document.createEvent("customEvent");
  }

  onChange() {
    this.changeEvent.initCustomEvent(
      "fieldMappingFormatChanged",
      true,
      true,
      null
    );
    this.element.dispatchEvent(this.changeEvent);
  }
}
