import axios from 'axios';
import { authenticityToken } from "../utils/authenticity_token";

class DatafileClient {
  constructor(accountId) {
    this.accountId = accountId;
  }

  mapFields(params) {
    const endpoint = `/accounts/${this.accountId}/integrations/datafile_integrations/field_mappings`;

    const data = new FormData();
    data.append("headers", params);

    return axios.post(endpoint, data, {
      headers: {
        "X-CSRF-Token": authenticityToken,
        "Content-Type": "multipart/form-data",
      }
    });
  }

  mapFieldsFromSource(datasourceId, sourcePath, headerRowNumber) {
    const endpoint = `/accounts/${this.accountId}/integrations/datafile_integrations/field_mappings_from_source`;

    const data = new FormData();
    data.append("source_path", sourcePath);
    data.append("datasource_id", datasourceId);
    data.append("header_row_number", headerRowNumber);

    return axios.post(endpoint, data, {
      headers: {
        "X-CSRF-Token": authenticityToken,
        "Content-Type": "multipart/form-data",
      }
    });
  }

  getFilesFromSource(datasourceId, sourcePath, filenameDatePattern) {
    const endpoint = `/accounts/${this.accountId}/integrations/datafile_integrations/files_from_source`;

    return axios.get(endpoint,{ params: {
      'source_path': sourcePath,
      'datasource_id': datasourceId,
      'filename_date_pattern': filenameDatePattern
      },
      headers: {
        "X-CSRF-Token": authenticityToken,
        "Content-Type": "multipart/form-data",
      }
    });
  }
}

export default DatafileClient;
