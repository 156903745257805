class SyncKeyCheckBoxFactory {
  constructor(integrationType) {
    this.integrationType = integrationType;
  }

  create(value, index) {
    const cell = document.createElement("td");

    const checkbox = this.createCheckBox(value, index);

    cell.appendChild(checkbox);
    cell.setAttribute("class", "checkbox-container");

    return cell;
  }

  createCheckBox(value, index) {
    const checkbox = document.createElement("input");

    checkbox.setAttribute("type", "checkbox");
    checkbox.setAttribute("class", "checkbox-lg");
    checkbox.setAttribute(
      "name",
      `${this.integrationType}[field_mappings_attributes][${index}][use_in_key]`
    );
    checkbox.setAttribute("value", value);
    checkbox.setAttribute("data-target", "field-mapping-list-item.useInKey");
    checkbox.setAttribute("data-action", "field-mapping-list-item#onToggleUseInKey");

    if (value) {
      checkbox.setAttribute("checked", value);
    }

    return checkbox;
  }
}

export default SyncKeyCheckBoxFactory;
