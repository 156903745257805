import { Controller } from "stimulus";
import sanitizeForDB from "../../utils/sanitize_for_db";

export default class extends Controller {
  static targets = [
    "originalSourceField", 
    "sourceField",
    "destinationField",
    "dateFormatField",
    "useInKey"
  ];

  connect() {
    this.element[this.identifier] = this;
  }

  onSourceFieldChange(event) {
    const sanitized = sanitizeForDB(this.originalSourceFieldTarget);
    this.sourceFieldTarget.value = sanitized;
    this.destinationFieldTarget.value = sanitized;
  }

  onDestinationFieldChange(event) {
    this.destinationFieldTarget.value = sanitizeForDB(
      this.destinationFieldTarget
    );
  }

  onFormatChange(event) {
    const dateFormat = this.targets.find("dateFormat");
    const dateRelatedFields = ["date", "timestamp", "datetime"];

    if (dateRelatedFields.includes(event.target.value)) {
      dateFormat.style.display = "flex";
      return;
    }

    dateFormat.style.display = "none";
    this.dateFormatFieldTarget.value = "";

    return;
  }

  onToggleUseInKey(event) {
    const checked = this.useInKeyTarget.checked;
    this.useInKeyTarget.setAttribute("value", checked);
  }
}
