import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "statusType", "workflowType" ]

  filter() {
    const status_setting = String(this.statusTypeTarget.value);
    const workflow_setting = String(this.workflowTypeTarget.value);
    const clean_uri = location.protocol + "//" + location.host + location.pathname;
    location = clean_uri + "?state=" + status_setting + "&workflow=" + workflow_setting;
  }
}
