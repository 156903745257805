import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sourcePath", "hiddenSourcePath"];

  connect() {
    this.dsTypes = {
      webhook: "Datasources::EmailWebhook",
      storage: "Datasources::DaasityStorage",
    };

    document.addEventListener("datafile:datasource-changed", (event) => {
      this.adaptToDatasource(event);
    });

    document.addEventListener("datafileUploader:fileUploaded", (event) => {
      this.setSourcePath(event);
    });
  }

  adaptToDatasource(event) {
    const { type } = event.detail;

    this.sourcePathTarget.disabled = false;

    if (type === this.dsTypes.webhook) {
      this.hiddenSourcePathTarget.value = "";

      this.sourcePathTarget.value = "";
      this.sourcePathTarget.disabled = true;

      this.element.setAttribute("style", "display: none");

      return;
    }

    if (type === this.dsTypes.storage) {
      this.sourcePathTarget.disabled = true;
    }

    this.element.setAttribute("style", "display: block");
  }

  setSourcePath(event) {
    this.sourcePathTarget.value = event.detail.response.path;
    this.hiddenSourcePathTarget.value = event.detail.response.path;
  }
}
