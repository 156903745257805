import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    "email",
    "redacted_at",
    "requested_by",
    "customer_email",
    "status-button"
    ];
  
  
  async onClickCheckStatus() {
    const email = this.targets.find('email').value;
    const customer_email = this.targets.find('customer_email')
    const redacted_at = this.targets.find('redacted_at')
    const requested_by = this.targets.find('requested_by')
    const requested_at = this.targets.find('requested_at')
    const status_button = this.targets.find('status_button')

    status_button.innerHTML = '<i class="fa-duotone fa-spinner fa-pulse"></i>';
    //call async endpoint status to check the status of the redaction
    try {
      const response = await fetch(`redaction/status?email=${email}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      status_button.innerHTML = 'Check Status';
      const data = await response.json();
      customer_email.innerHTML = data.email;
      
      if (data.requested_by == undefined) {
        redacted_at.innerHTML = ' Not Found/Not Requested';
        requested_by.innerHTML = 'n/a';
      }
      else {
        requested_by.innerHTML = data.requested_by;
        redacted_at.innerHTML = data.redacted_at
          ? `Processed on<br>${moment(data.redacted_at).format('ddd, MMM DD, YYYY h:mmA z')}`
          : `Pending since <br>${moment(data.requested_at).format('ddd, MMM DD, YYYY h:mmA z')}`;
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }
}
