import { Controller } from "stimulus";

import DatafileClient from "../../clients/datafile";
import { camelCase } from "../../utils/camel_case";

import DatafileParser from "../../utils/datafile_parser";

export default class extends Controller {
  static targets = ["integrationType", "datasourceId", "sourcePath", "headerRowNumber"];

  connect() {
    document.addEventListener("fileUploader:fileReady", (event) =>
      this.parse(event)
    );
  }

  async parse(event) {
    const parser = new DatafileParser(event.detail.file.name);
    const headerNumber = this.headerRowNumberTarget.value;

    const remapStartedEvent = new CustomEvent("fieldMappingsList:remapStarted");
    document.dispatchEvent(remapStartedEvent);

    const headers = await parser.parseHeaders(event.detail.file, headerNumber);

    this.remap(headers);
  }

  async remapFromSource(event) {
    const accountId = this.targets.find("accountId").value;
    const remapStartedEvent = new CustomEvent("fieldMappingsList:remapFromSourceStarted");
    document.dispatchEvent(remapStartedEvent);
    let selectedFile = document.querySelector('#files_from_source .selected');
    let sourcePathTarget = selectedFile ? selectedFile.innerText : this.sourcePathTarget.value;

    const client = new DatafileClient(accountId);
    let response;

    try {
      response = await client.mapFieldsFromSource(
        this.datasourceIdTarget.value,
        sourcePathTarget,
        this.headerRowNumberTarget.value
      );
    } catch(error) {
      const remapFailedEvent = new CustomEvent("fieldMappingsList:remapFromSourceFailed");
      document.dispatchEvent(remapFailedEvent);

      return;
    }
    const remapReadyEvent = new CustomEvent("fieldMappingsList:remapReady", {
      detail: {
        fieldMappings: response.data,
        integrationType: camelCase(this.integrationTypeTarget.value),
      },
      bubbles: true,
      cancelable: true,
    });

    document.dispatchEvent(remapReadyEvent);
  }

  async loadFilesFromSource(event) {
    event.preventDefault();
    const accountId = this.targets.find("accountId").value;
    
    const client = new DatafileClient(accountId);
    let response;

    try {
      let tbody = document.getElementById("files_from_source");
      let filenameDatePattern = $('#comma_file_integration_filename_date_pattern').val();
      tbody.innerHTML = '<tr class="no-hover"><td><i class="fas fa-spinner fa-spin"></i></td></tr>';

      response = await client.getFilesFromSource(
        this.datasourceIdTarget.value,
        this.sourcePathTarget.value,
        filenameDatePattern
      );
      tbody.innerHTML = '';

      response.data.forEach((file, idx) => {
        let selected_class = '';
        if (idx == 0 ) {
          selected_class = 'class="selected"'
        }
        tbody.innerHTML += `<tr style="cursor: pointer;" ${selected_class}> <td>${file}</td></tr>`
      }
      );
    } catch(error) {
      let tbody = document.getElementById("files_from_source");

      switch( error.response.status ) {
      case 500:
        tbody.innerHTML = '<div class="text-danger p-2">There is a connection issue with your datasource, please check the credentials.</div>';
        break;
      case 404:
        tbody.innerHTML = '<div class="p-2">No matching files found</div>';
        break;
      }


      return;
    }
  }

  enableLoadFromSourceButton() {
    let button = document.getElementById("load_from_source")
    !!(this.datasourceIdTarget.value && this.sourcePathTarget.value) ? button.disabled = false : button.disabled = true
  }

  async remap(headers) {
    const accountId = this.targets.find("accountId").value;

    const client = new DatafileClient(accountId);
    const response = await client.mapFields(headers);

    const remapReadyEvent = new CustomEvent("fieldMappingsList:remapReady", {
      detail: {
        fieldMappings: response.data,
        integrationType: camelCase(this.integrationTypeTarget.value),
      },
      bubbles: true,
      cancelable: true,
    });

    document.dispatchEvent(remapReadyEvent);
  }
}
