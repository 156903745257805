import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message", "spinner"];

  connect() {
    document.addEventListener("datafileUploader:fileUploadStarted", () =>
      this.onStarted()
    );
    document.addEventListener("datafileUploader:fileUploaded", () =>
      this.onUploaded()
    );
  }

  onStarted() {
    this.element.style.display = "block";
    this.spinnerTarget.style.display = "inline-block";
    this.messageTarget.innerHTML = "Uploading File";
  }

  onUploaded() {
    this.messageTarget.innerHTML = "File Successfully Uploaded";
    this.spinnerTarget.style.display = "none";
    $(this.element).fadeOut(4000);
  }
}
