export default function sanitizeForDB(field) {
  return field.value
    .trim()
    .replace(/\s+/g, '_')
    .replace(/:/g, '_')
    .replace(/"/g, '')
    .replace(/[^0-9A-Za-z_]/g, " ")
    .replace(/[' ']/g, "_")
    .toLowerCase();
}
