import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("datafile:datasource-changed", (event) => this.adaptToDatasource(event));
  }

  adaptToDatasource(event) {
    if (event.detail.type == "Datasources::Sftp") {
      this.element.setAttribute("style", "display: block");
      return;
    }

    if (event.detail.type == "Datasources::S3") {
      this.element.setAttribute("style", "display: block");
      return;
    }

    this.element.setAttribute("style", "display: none");
  }
}
