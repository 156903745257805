class Button {

  loadButton(element) {
    $(element)
      .addClass('btn-light disabled')
      .attr('disabled', true)
      .removeClass('btn-primary btn-danger btn-action')
      .html($(element).data('loading-text'))
  }

  resetButton(element) {
    $(element)
      .addClass('btn-light')
      .removeClass('btn-primary btn-danger disabled')
      .html($(element).data('text'))
      .prop('disabled', false)
      .blur()
  }

  successButton(element) {
    $(element)
      .addClass('btn-primary')
      .removeClass('btn-light btn-danger btn-action disabled')
      .html($(element).data('success-text'))
      .prop('disabled', false)
  }

  errorButton(element){
    $(element)
      .addClass('btn-danger')
      .removeClass('btn-light btn-action btn-primary disabled')
      .html($(element).data('error-text'))
      .prop('disabled', false)
  }
}

export default Button;
