import { Controller } from 'stimulus';

export default class extends Controller {

  onClickTestEnableReviewSettings() {
      let interval = setInterval(() => {
        let testButton = $('#test_provisioning_connection_button');
        if (testButton.html() == 'Invalid connection') {
          clearInterval(interval)
        }
        if (testButton.html() == 'Valid connection') {
          $('#submit-button').prop('disabled', false);
          clearInterval(interval)
        }
      }, 3000);
    }
}
