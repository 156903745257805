import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["banner", "emailLink"];

  connect() {
    document.addEventListener("datafile:datasource-changed", (event) =>
      this.adaptEmailBanner(event)
    );
  }

  adaptEmailBanner(event) {
    if (event.detail.type == "Datasources::EmailWebhook") {
      const email = `${event.detail.emailKey}@inbound.daasity.com`;

      this.element.setAttribute("style", "display: block");
      this.emailLinkTarget.setAttribute("href", `mailto ${email}`);
      this.emailLinkTarget.innerHTML = email;

      return;
    }

    this.element.setAttribute("style", "display: none");
  }
}
