class FieldConfigFactory {
  constructor(integrationType) {
    this.integrationType = integrationType;
  }

  create(value, formatValue, index) {
    const cell = document.createElement("td");

    const wrapper = this.createWrapper(formatValue);

    const firstPart = this.createFirstDatePart(index);
    const secondPart = this.createSecondDatePart(index);
    const thirdPart = this.createThirdDatePart(index);
    const input = this.createInput(value, index);

    wrapper.appendChild(firstPart);
    wrapper.appendChild(secondPart);
    wrapper.appendChild(thirdPart);
    wrapper.appendChild(input);

    cell.appendChild(wrapper);
    cell.setAttribute("class", "field-mapping-field-config-column");

    return cell;
  }

  createWrapper(formatValue) {
    const wrapper = document.createElement("div");
    const dateRelatedFields = ["date", "timestamp", "datetime"];

    const display = dateRelatedFields.includes(formatValue) ? "flex" : "none";

    wrapper.setAttribute("data-controller", "field-mapping-date-format");
    wrapper.setAttribute("data-target", "field-mapping-list-item.dateFormat");
    wrapper.setAttribute("class", "options-wrapper");
    wrapper.setAttribute("style", `display: ${display}`);

    return wrapper;
  }

  createFirstDatePart(index) {
    return this.createSelect(
      `${this.integrationType}[field_mappings_attributes][${index}][first_date_format_part]`,
      "field-mapping-date-format.firstElement"
    );
  }

  createSecondDatePart(index) {
    return this.createSelect(
      `${this.integrationType}[field_mappings_attributes][${index}][second_date_format_part]`,
      "field-mapping-date-format.secondElement"
    );
  }

  createThirdDatePart(index) {
    return this.createSelect(
      `${this.integrationType}[field_mappings_attributes][${index}][third_date_format_part]`,
      "field-mapping-date-format.thirdElement"
    );
  }

  createSelect(name, dataTarget) {
    const select = document.createElement("select");

    select.setAttribute("name", name);
    select.setAttribute("data-target", dataTarget);
    select.setAttribute(
      "data-action",
      "field-mapping-date-format#onUpdateValue"
    );
    select.setAttribute("name", name);

    select.appendChild(this.createOption("YYYY", "Y"));
    select.appendChild(this.createOption("YY", "y"));
    select.appendChild(this.createOption("MM", "m"));
    select.appendChild(this.createOption("DD" , "d"));

    return select;
  }

  createOption(text, value) {
    const option = document.createElement("option");

    option.text = text;
    option.value = value;

    return option;
  }

  createInput(value, index) {
    const field = document.createElement("input");

    const targets = "field-mapping-date-format.dateFormat field-mapping-list-item.dateFormatField";

    const fieldValue = value ? value !== null : "";

    field.setAttribute("data-target", targets);
    field.setAttribute("type", "hidden");
    field.setAttribute(
      "name",
      `${this.integrationType}[field_mappings_attributes][${index}][date_format]`
    );
    field.setAttribute("value", fieldValue);

    return field;
  }
}

export default FieldConfigFactory;
