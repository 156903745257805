import xlsx from "xlsx";

class ExcelParser {
  constructor() {
    this.parser = xlsx;
  }

  parseHeaders(file, header_row) {
    const reader = new FileReader();

    return new Promise(resolve => {
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = this.parser.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]
        const sheet = workbook.Sheets[sheetName];
        const csv_content = this.parser.utils.sheet_to_csv(sheet);

        resolve(csv_content);
      }
    });
  }
}

export default ExcelParser;
