import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message", "spinner"];

  connect() {
    document.addEventListener("fieldMappingsList:remapStarted", () =>
      this.onStarted("We are mapping the fields from your file")
    );
    document.addEventListener("fieldMappingsList:remapReady", () =>
      this.onReady()
    );
    document.addEventListener("fieldMappingsList:remapFromSourceStarted", () => 
      this.onStarted("We are mapping the fields from your file")
    );

    document.addEventListener("fieldMappingsList:remapFromSourceFailed", () => 
      this.onFail("Mapping has failed. Please check your data source / file")
    );
  }

  onStarted(message) {
    this.element.style.display = "block";
    this.spinnerTarget.style.display = "inline-block";
    this.element.setAttribute("class", "alert alert-info fade show")
    this.messageTarget.innerHTML = message;
  }

  onReady() {
    this.messageTarget.innerHTML = "Fields Mapped Successfully";
    this.spinnerTarget.style.display = "none";

    let v = document.getElementById('datafileFieldMappingsWrapper');
    if( v ) {
      v.style.display = 'block';
    }

    $(this.element).fadeOut(3000);
  }

  onFail(message) {
    this.messageTarget.innerHTML = message;
    this.spinnerTarget.style.display = "none";
    this.element.setAttribute("class", "alert alert-danger fade show")
  }
}
