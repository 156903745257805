import "core-js/stable";
import "regenerator-runtime/runtime";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();

import ProvisioningController from "./controllers/provisioning_controller";
import TestConnectionController from "./controllers/test_connection_controller";
import RedactionController from "./controllers/redaction_controller";
import TestTransformFileController from "./controllers/test_transform_file_controller";
import KwiController from "./controllers/kwi_controller";
import HAInfluencersList from "./controllers/hype_auditor/influencers/list_controller";
import HAInfluencersItem from "./controllers/hype_auditor/influencers/list_item_controller";
import AscIntegrationController from "./controllers/asc_integration_controller";
import DevopsController from "./controllers/devops_controller";

application.register("provisioning", ProvisioningController);
application.register("test_connection", TestConnectionController);
application.register("redaction", RedactionController);
application.register("kwi", KwiController);
application.register("hype_auditor/influencers/list", HAInfluencersList);
application.register("hype_auditor/influencers/list_item", HAInfluencersItem);
application.register("asc-integration", AscIntegrationController);
application.register("devops", DevopsController);
application.register("test_transform_file", TestTransformFileController);

// field mappings
import DateFormatController from "./controllers/field_mappings/date_format_controller";
import FormatSelectController from "./controllers/field_mappings/format_select_controller";
import FieldMappingListItem from "./controllers/field_mappings/list_item";
import FieldMappingList from './controllers/field_mappings/list';

application.register("field-mapping-date-format", DateFormatController);
application.register("field-mapping-format-select", FormatSelectController);
application.register("field-mapping-list-item", FieldMappingListItem);
application.register("field-mapping-list", FieldMappingList);

// datafile
import DatasourceSelector from "./controllers/datafile/datasource_selector";
import PathnameField from "./controllers/datafile/pathname_field";
import DataMappingFields from "./controllers/datafile/data_mapping_fields";
import DatafileUploadHandler from './controllers/datafile/file_upload_handler';
import DatafileForm from './controllers/datafile/form';
import EmailWebhookBanner from './controllers/datafile/email_webhook_banner';
import MappingStatusBanner from './controllers/datafile/mapping_status_banner';
import UploadStatusBanner from './controllers/datafile/upload_status_banner';
import DatafileFilenameDatePattern from './controllers/datafile/filename_date_pattern';

application.register('datafile-datasource-selector', DatasourceSelector);
application.register('datafile-pathname-field', PathnameField);
application.register('datafile-datamapping-fields', DataMappingFields);
application.register('datafile-upload-handler', DatafileUploadHandler);
application.register('datafile-form', DatafileForm);
application.register('datafile-email-webhook-banner', EmailWebhookBanner);
application.register("datafile-mapping-status-banner", MappingStatusBanner);
application.register("datafile-upload-status-banner", UploadStatusBanner);
application.register("datafile-filename-date-pattern", DatafileFilenameDatePattern);

// file uploader
import DaasityFileUploader from './controllers/daasity_file_uploader_controller';

application.register('daasity-file-uploader', DaasityFileUploader);

// Infinte Scroll
import InfiniteScroll from "./utils/infinite_scroll_controller";
application.register("infinite-scroll", InfiniteScroll);

import moment from 'moment-timezone';
global.moment = moment;
