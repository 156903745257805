import axios from 'axios';
import Button from './button';

class KwiIntegrationForm {
  constructor(targets) {

    this.name = targets.find('name');
    this.username = targets.find('username');
    this.password = targets.find('password');
    this.host = targets.find('host');

    this.testSftpConnectionButton = targets.find('testSftpConnectionButton');

    this.reportsListsTableButton = targets.find('reportsListsTableButton');
    this.reportsListsTable       = targets.find('reportsListsTable');
    this.reportsListsTableBody   = targets.find('reportsListsTableBody');

    this.reportsProcessedListsTableButton = targets.find('reportsProcessedListsTableButton');
    this.reportsProcessedListsTable       = targets.find('reportsProcessedListsTable');
    this.reportsProcessedListsTableBody   = targets.find('reportsProcessedListsTableBody');

    this.reportsNoDataListsTableButton = targets.find('reportsNoDataListsTableButton');
    this.reportsNoDataListsTable       = targets.find('reportsNoDataListsTable');
    this.reportsNoDataListsTableBody   = targets.find('reportsNoDataListsTableBody');

    this.notificationReportsError   = targets.find('notificationReportsError');
    this.notificationProcessedError = targets.find('notificationProcessedError');
    this.notificationNoDataError    = targets.find('notificationNoDataError');

    this.buildTable = (data, list, block) => {
      data.forEach( row => {
        let $tr = $('<tr>').append(
          $('<td>').text(row[0]),
          $('<td>').text(row[1]),
          $('<td>').text(row[2])
        );
        $(list).append($tr)
      })
      $(block).removeAttr('hidden')
    }

    this.clearReports = (note, list, block) => {

      const error_div     = $(note)
      const reports_list  = $(list)
      const reports_block = $(block)

      error_div.text('')
      reports_list.empty()

      error_div.attr('hidden', true)
      reports_block.attr('hidden', true)
    }
  }

  async testSftpConnection() {
    let buttonDisplay = new Button;
    let buttonElement = this.testSftpConnectionButton;

    buttonDisplay.loadButton(buttonElement);

    const endpoint = this.testSftpConnectionButton.dataset.url;
    const params = {
      host: this.host.value,
      username: this.username.value,
      password: this.password.value
    }

    const response = await axios.get(endpoint, { params });
    const isValid = response.data;

    if (isValid) {
      buttonDisplay.successButton(buttonElement);
      return
    } else {
      buttonDisplay.errorButton(buttonElement);
    }
  }

  async getReportsList() {
    const buttonDisplay = new Button;
    const buttonElement = this.reportsListsTableButton;
    const error_block   = this.notificationReportsError;

    const buildTable    = this.buildTable
    const clearReports  = this.clearReports

    clearReports('.reports-error', '.reports-list', '.reports-block' )
    buttonDisplay.loadButton(buttonElement);

    const endpoint = buttonElement.dataset.url;
    const params = {
      host: this.host.value,
      username: this.username.value,
      password: this.password.value
    }

    await axios.get(endpoint, { params } )
      .then(function (response) {
        buildTable(response.data, '.reports-list', '.reports-block')
        buttonDisplay.successButton(buttonElement)
      })
      .catch(function (error) {
        error_block.removeAttribute('hidden')
        error_block.innerHTML += JSON.stringify(error.message, null, '\t')

        buttonDisplay.errorButton(buttonElement)
      })
  }

  async getProcessedReportsList() {
    const buttonDisplay = new Button;
    const buttonElement = this.reportsProcessedListsTableButton;
    const error_block   = this.notificationProcessedError;
    const buildTable    = this.buildTable
    const clearReports  = this.clearReports

    clearReports('.processed-error', '.processed-list', '.processed-block' )
    buttonDisplay.loadButton(buttonElement);

    const endpoint = buttonElement.dataset.url;
    const params = {
      host: this.host.value,
      username: this.username.value,
      password: this.password.value
    }

    await axios.get(endpoint, { params } )
      .then(function (response) {
        buildTable(response.data, '.processed-list', '.processed-block')
        buttonDisplay.successButton(buttonElement)
      })
      .catch(function (error) {
        error_block.removeAttribute('hidden')
        error_block.innerHTML += JSON.stringify(error.message, null, '\t')

        buttonDisplay.errorButton(buttonElement)
      })
  }

  async getNoDataReportsList() {
    const buttonDisplay = new Button;
    const buttonElement = this.reportsNoDataListsTableButton;
    const error_block   = this.notificationNoDataError;
    const buildTable    = this.buildTable
    const clearReports  = this.clearReports

    clearReports('.no-data-error', '.no-data-list', '.no-data-block' )
    buttonDisplay.loadButton(buttonElement);

    const endpoint = buttonElement.dataset.url;

    const params = {
      host: this.host.value,
      username: this.username.value,
      password: this.password.value
    }

    await axios.get(endpoint, { params } )
      .then(function (response) {
        buildTable(response.data, '.no-data-list', '.no-data-block')
        buttonDisplay.successButton(buttonElement)
      })
      .catch(function (error) {
        error_block.removeAttribute('hidden')
        error_block.innerHTML += JSON.stringify(error.message, null, '\t')

        buttonDisplay.errorButton(buttonElement)
      })
  }

  enableReportPrefix(target) {
    target.removeAttr("disabled");
  }

  disableReportPrefix(target) {
    target.prop("disabled", true);
    target.val('')
  }

  formatFileNameRelatedField(target) {
    const formattedValue = target.value.replace(/[^0-9A-Za-z_]/g, ' ').trim().replace(/[' ']/g, '_').toLowerCase();
    target.value = formattedValue;
  }
}

export default KwiIntegrationForm;
