import { Controller } from "stimulus";
import api from "../../../clients/hype_auditor";
import renderTemplate from "../../../utils/template_renderer";

const renderInfluencer = ({
  template,
  influencer,
  accountId,
  integrationId,
}) => {
  return renderTemplate(template, {
    "influencer.id": influencer.id,
    "influencer.username": influencer.username,
    "influencer.status": influencer.status,
    "influencer.status_date": influencer.status_date_formatted,
    "influencer.status_message": influencer.status_message,
    "integration.id": integrationId,
    "account.id": accountId,
  });
};

export default class extends Controller {
  static targets = [
    "usernames",
    "influencerTemplate",
    "emptyInfluencersTemplate",
    "influencersList",
    "requestReports",
    "sortBy",
  ];

  connect() {
    this.updateList();
  }

  updateList() {
    const { accountId, integrationId, sortBy } = this.element.dataset;
    api.influencers(accountId, integrationId, sortBy).then((response) => {
      if (response.headers.etag != this.element.dataset.etag) {
        this.element.dataset.etag = response.headers.etag;
        this.updateInfluencersList(response.data);
      }
    });
  }

  sortByUsername() {
    this.sortBy("username");
  }

  sortByStatus() {
    this.sortBy("status");
  }

  sortBy(sortBy) {
    this.element.dataset.sortBy = sortBy;
    this.updateList();
  }

  addInfluencers(e) {
    e.preventDefault();

    const influencers = this.usernamesTarget.value.split("\n");
    const { accountId, integrationId } = this.element.dataset;

    const pull = this.requestReportsTarget.checked;

    api
      .addInfluencers(accountId, integrationId, influencers, pull)
      .then((response) => {
        this.usernamesTarget.value = "";
        this.updateList();
      });
  }

  refreshAll() {
    const { accountId, integrationId } = this.element.dataset;

    api
      .updateReports(accountId, integrationId)
      .then((response) => this.updateList());
  }

  updateInfluencersList(influencers) {
    if (influencers.length === 0) {
      const html = this.emptyInfluencersTemplateTarget.innerHTML;
      this.influencersListTarget.innerHTML = html;

      return;
    }

    const { accountId, integrationId } = this.element.dataset;
    const template = this.influencerTemplateTarget.innerHTML;

    const html = influencers.map((influencer) => {
      return renderInfluencer({
        template,
        integrationId,
        accountId,
        influencer,
      });
    });

    this.influencersListTarget.innerHTML = html.join("");
  }
}
