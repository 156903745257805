import { Controller } from 'stimulus';

export default class extends Controller {

  onClickTestTransformFile(e) {
      let interval = setInterval(() => {
        let testButton = $('.test_transformation_file_button:visible');
        if (testButton.html() == 'File not found') {
          clearInterval(interval)
        }
        if (testButton.html() == 'Success') {
          $('.submit-button:visible').prop('disabled', false);
          clearInterval(interval)
        }
      }, 500);
    }
}