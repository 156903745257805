import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["uploadField"]

  openFiles(event) {
    event.preventDefault();
    this.uploadFieldTarget.click();
  }

  onChooseFile(event) {
    const fileObject = event.target.files[0];

    const fileReadyEvent = new CustomEvent('fileUploader:fileReady', {
      detail: {
        file: fileObject
      },
      bubbles: true,
      cancelable: false
    });

    document.dispatchEvent(fileReadyEvent);
  }
}
