class OriginalSourceNameInputFactory {
  constructor(integrationType) {
    this.integrationType = integrationType;
  }

  create(value, index) {
    const cell = document.createElement("td");
    const wrapper = this.createWrapper();
    const input = this.createInput(value, index);

    wrapper.appendChild(input);
    cell.appendChild(wrapper);

    return cell;
  }

  createWrapper() {
    const wrapper = document.createElement("div");

    wrapper.setAttribute("class", "form-group string required");

    return wrapper;
  }

  createInput(value, index) {
    const field = document.createElement("input");
    const fieldName = `${this.integrationType}[field_mappings_attributes][${index}][original_source_name]`;

    field.setAttribute("type", "text");
    field.setAttribute("name", fieldName);
    field.setAttribute("value", value);
    field.setAttribute("class", "form-control is-valid string required");
    field.setAttribute(
      "data-action",
      "keyup->field-mapping-list-item#onSourceFieldChange"
    );
    field.setAttribute(
      "data-target",
      "field-mapping-list-item.originalSourceField"
    );

    return field;
  }
}

export default OriginalSourceNameInputFactory;
