import { Controller } from "stimulus";
import api from "../../../clients/hype_auditor";

export default class extends Controller {
  delete() {
    if (confirm(`Sure you want to delete ${this.influencerHandle()}`)) {
      this.performDelete();
    }
  }

  refreshReport() {
    api
      .updateReport(
        this.element.dataset.accountId,
        this.element.dataset.integrationId,
        this.influencerHandle()
      )
      .then((response) => this.list.updateList());
  }

  influencerHandle() {
    return this.element.dataset.influencerUsername;
  }

  get list() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="hype_auditor/influencers/list"]'),
      "hype_auditor/influencers/list"
    );
  }

  performDelete() {
    api.deleteInfluencers(
      this.element.dataset.accountId,
      this.element.dataset.integrationId,
      [this.influencerHandle()]
    ).then(response => this.element.parentNode.removeChild(this.element));
  }
}
