import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;
  }

  firstElement() {
    return this.targets.find("firstElement").value;
  }

  secondElement() {
    return this.targets.find("secondElement").value;
  }

  thirdElement() {
    return this.targets.find("thirdElement").value;
  }

  fieldElement() {
    return this.targets.find("dateFormat");
  }

  onUpdateValue() {
    const newValue = `%${this.firstElement()}-%${this.secondElement()}-%${this.thirdElement()}`;
    this.fieldElement().value = newValue;
  }
}
