import { Controller } from "stimulus";
import OriginalSourceNameInputFactory from "../../components/field_mappings/cells/original_source_name_input_factory";
import DestinationNameInputFactory from "../../components/field_mappings/cells/destination_name_input_factory";
import SourceNameInputFactory from "../../components/field_mappings/cells/source_name_input_factory";
import FormatFactory from "../../components/field_mappings/cells/format_factory";
import SyncKeyCheckBoxFactory from "../../components/field_mappings/cells/sync_key_checkbox_factory";
import IdInputFactory from "../../components/field_mappings/cells/id_input_factory";
import FieldConfigFactory from "../../components/field_mappings/cells/field_config_factory";

export default class extends Controller {
  connect() {
    document.addEventListener("fieldMappingsList:remapStarted", () =>
      this.clearList()
    );

    document.addEventListener("fieldMappingsList:remapFromSourceStarted", () => 
      this.clearList()
    );
    document.addEventListener("fieldMappingsList:remapReady", () =>
      this.populateList(event)
    );
  }

  clearList() {
    this.element.innerHTML = "";
  }

  populateList(event) {
    const { integrationType } = event.detail;

    event.detail.fieldMappings.map((fieldMapping, index) => {
      if (fieldMapping.original_source_name == "__filename") {
        next;
      }
      
      const row = this.element.insertRow();
           
      const originalSourceNameInput = new OriginalSourceNameInputFactory(
        integrationType
      );
      const originalSourceName = originalSourceNameInput.create(
        fieldMapping.original_source_name,
        index
      );

      const sourceNameInput = new SourceNameInputFactory(integrationType);
      const sourceName = sourceNameInput.create(
        fieldMapping.source_name,
        index
      );

      originalSourceName.appendChild(sourceName);

      const destinationNameInput = new DestinationNameInputFactory(
        integrationType
      );
      const destinationName = destinationNameInput.create(
        fieldMapping.destination_name,
        index
      );

      const formatSelect = new FormatFactory(integrationType);
      const format = formatSelect.create(fieldMapping.format_as, index);

      const syncKeyCheckBox = new SyncKeyCheckBoxFactory(integrationType);
      const syncKey = syncKeyCheckBox.create(fieldMapping.use_in_key, index);

      const fieldConfig = new FieldConfigFactory(integrationType);
      const config = fieldConfig.create(
        fieldMapping.date_format,
        fieldMapping.format_as,
        index
      );

      row.setAttribute("class", "field-mapping-row");
      row.setAttribute("data-controller", "field-mapping-list-item");

      row.appendChild(originalSourceName);
      row.appendChild(destinationName);
      row.appendChild(format);
      row.appendChild(syncKey);
      row.appendChild(config);
    });
  }
}
