import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["accounts", "pagination"]

  initialize() {
    this.loading = false;
  }

    scroll () {
      const nextPage = this.paginationTarget.querySelector("a[rel='next']");

      if (nextPage == null) return;

      const body = document.body;
      const html = document.documentElement;
      const url = nextPage.href;
      const height = Math.max( body.scrollHeight, body.offsetHeight,
                             html.clientHeight, html.scrollHeight, html.offsetHeight );


      if (window.pageYOffset >= height - window.innerHeight - 500) {
        if (this.loading) return;

        this.loadMore(url);
      }
    }

    loadMore (url) {
    this.loading = true;
      Rails.ajax({
        url: url,
        type: "GET",
        dataType: "json",
        success: (data) => {
          this.accountsTarget.insertAdjacentHTML("beforeend", data.accounts);
          this.paginationTarget.innerHTML = data.pagination;
          this.loading = false;
        }
      });
    }

}
