import { Controller } from 'stimulus';

import KwiIntegrationForm from '../components/kwiIntegrationForm';
import Button from '../components/button';

export default class extends Controller {

  onReportOptionsChange(event) {
    const form = new KwiIntegrationForm(this.targets);
    const prifixTarget = $(document).find("#report_prefix_" + event.target.id.split('report_')[1])

    if (event.target.checked) {
      form.enableReportPrefix(prifixTarget);
    } else {
      form.disableReportPrefix(prifixTarget)
    }
  }

  onChangeDestinationField() {
    const form = new KwiIntegrationForm(this.targets);
    form.formatFileNameRelatedField(event.target);
  }

  onClickGetProcessedReportsList(event) {
    const form = new KwiIntegrationForm(this.targets);
    form.getProcessedReportsList();
  }

  onClickGetFilesWithNoDataList(event) {
    const form = new KwiIntegrationForm(this.targets);
    form.getNoDataReportsList();
  }

  onClickGetReportsList(event) {
    const form = new KwiIntegrationForm(this.targets);
    form.getReportsList();
  }

  onClickTestSftpConnection() {
    const form = new KwiIntegrationForm(this.targets);
    form.testSftpConnection();
  }
}
