import { Controller } from "stimulus";
import api from '../clients/asc';

import FileUploader from '../components/fileUploader';

export default class extends Controller {
  static targets = [
    "marketplaceId",
    "sellerId",
    "uploadStatus",
    "accountId",
    "integrationId",
    "uploadedFilePath",
    "eventDate",
    "infoMessage",
    "browseLabel"
  ];

  onClickUploadFileButton(event) {
    event.preventDefault();

    const uploader = new FileUploader(
      this.targets.find('accountId').value, 
      this.targets.find('fileUploader')
    );

    uploader.openFileDialog();
  }

  onPickFile(event) {
    const browseLabel = this.targets.find('browseLabel');

    const fileObject = event.target.files[0];
    const uploader = new FileUploader(
      this.targets.find('accountId').value, 
      this.targets.find('fileUploader')
    );

    browseLabel.innerHTML = fileObject.name;
  }

  async onClickUploadButton() {
    const uploader = new FileUploader(
      this.targets.find('accountId').value, 
      this.targets.find('fileUploader')
    );

    const path = this.targets.find("uploadedFilePath");
    const fileObject = this.targets.find('fileUploader').files[0];
    const endpoint = this.targets.find("endpoint").value;
    const browseLabel = this.targets.find('browseLabel');
    const uploadButton = this.targets.find('uploadButton');

    if (!this.isEventDatePresent()) {
      return;
    }

    browseLabel.innerHTML = 'Uploading...';
    uploadButton.disabled = true;

    const response = await uploader.upload(fileObject, endpoint);

    path.value = response.data.path;

    const datafileResponse = await this.triggerDatafileCreation();

    if (datafileResponse.status == 200) {
      window.location.reload();
    }
  }

  isEventDatePresent() {
    const eventDate = this.targets.find("eventDate");
    const eventDateInfo = this.targets.find("invalidDateInfo");

    eventDate.classList.remove('is-invalid');
    eventDateInfo.innerHTML =  "";

    if (eventDate.value == "") {
      eventDate.classList.add('is-invalid');
      eventDateInfo.innerHTML =  "Event Date is Required";

      return false;
    }

    return true;
  }

  triggerDatafileCreation() {
    const accountId = this.targets.find("accountId").value;
    const integrationId = this.targets.find("integrationId").value;
    const marketplaceId = this.targets.find("marketplaceId").value;
    const sellerId = this.targets.find("sellerId").value;
    const path = this.targets.find("uploadedFilePath");
    const eventDate = this.targets.find("eventDate");

    const response = api.createDatafile(
      accountId,
      integrationId,
      marketplaceId,
      sellerId,
      path.value,
      eventDate.value
    )

    return response;
  }
}
