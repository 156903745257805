class FormatFactory {
  constructor(integrationType) {
    this.integrationType = integrationType;
  }

  create(selectedValue, index) {
    const cell = document.createElement("td");
    const wrapper = this.createWrapper();
    const select = this.createSelect(index);

    const opts = this.createOptions(selectedValue);

    opts.map((option) => {
      select.appendChild(option);
    });

    wrapper.appendChild(select);
    cell.appendChild(wrapper);

    return cell;
  }

  createWrapper() {
    const wrapper = document.createElement("div");
    wrapper.setAttribute(
      "class",
      "form-group select required form-group-valid"
    );

    return wrapper;
  }

  createSelect(index) {
    const select = document.createElement("select");

    select.setAttribute(
      "class",
      "form-control is-valid select required form-control"
    );
    select.setAttribute(
      "name",
      `${this.integrationType}[field_mappings_attributes][${index}][format_as]`
    );
    select.setAttribute("data-target", "field-mapping-list-item.formatAs");
    select.setAttribute(
      "data-action",
      "field-mapping-list-item#onFormatChange"
    );

    return select;
  }

  createOptions(selectedValue) {
    return this.options().map((option) => {
      const opt = document.createElement("option");
      opt.text = option.label;
      opt.value = option.value;

      if (selectedValue == option.value) {
        opt.setAttribute("selected", "selected");
      }

      return opt;
    });
  }

  options() {
    return [
      { label: "Boolean", value: "boolean" },
      { label: "Currency", value: "currency" },
      { label: "Date", value: "date" },
      { label: "Decimal", value: "decimal" },
      { label: "Integer", value: "integer" },
      { label: "Percentage", value: "percentage" },
      { label: "String/Text", value: "string" },
      { label: "String/Text", value: "string" },
      { label: "Time", value: "time" },
      { label: "Timestamp", value: "timestamp" },
    ];
  }
}

export default FormatFactory;
