import { Controller } from "stimulus";
import axios from "axios";

import { authenticityToken } from "../../utils/authenticity_token";

export default class extends Controller {
  static targets = ["accountId", "loading", "datasource"];

  connect() {
    document.addEventListener("fileUploader:fileReady", (event) =>
      this.performUpload(event)
    );
    document.addEventListener("datafile:datasource-changed", (event) =>
      this.updateDatasourceState(event)
    );
  }

  async performUpload(event) {
    if (this.datasourceTarget.value != "Datasources::DaasityStorage") {
      return;
    }

    const uploadStartedEvent = new CustomEvent("datafileUploader:fileUploadStarted", {
      bubbles: true,
      cancelable: false,
    });

    document.dispatchEvent(uploadStartedEvent);

    const path = `/accounts/${this.accountIdTarget.value}/storage_files`;
    const data = new FormData();
    data.append("file", event.detail.file);

    const response = await axios.post(path, data, {
      headers: {
        "X-CSRF-Token": authenticityToken,
        "Content-Type": "multipart/form-data",
      },
    });

    const uploadedEvent = new CustomEvent("datafileUploader:fileUploaded", {
      detail: {
        response: response.data,
      },
      bubbles: true,
      cancelable: false,
    });

    document.dispatchEvent(uploadedEvent);
  }

  updateDatasourceState(event) {
    this.datasourceTarget.value = event.detail.type;
  }
}
