export default function renderTemplate(template, variables) {
  template;

  Object.keys(variables).forEach((varName) => {
    const find = new RegExp(`%{${varName}}`, "g");
    template = template.replace(find, variables[varName]);
  });

  return template;
}
