import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["datasource"];

  connect() {
    $('#datasource_id').on('select2:select', function () {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });
  }

  onChange() {
    const event = new CustomEvent("datafile:datasource-changed", {
      detail: {
        type: this.datasourceTarget.selectedOptions[0].getAttribute(
          "data-type"
        ),
        emailKey: this.datasourceTarget.selectedOptions[0].getAttribute(
          "data-email"
        ),
        id: this.datasourceTarget.value,
      },
      bubbles: true,
      cancelable: false,
    });

    document.dispatchEvent(event);
  }
}
