import CsvParser from "./csv_parser";
import ExcelParser from "./excel_parser";

class DatafileParser {
  constructor(filename) {
    this.extension = filename.split('.').pop();
  }

  async parseHeaders(file, header_row) {
    const csv_parser = new CsvParser();
    const excel_parser = new ExcelParser();

    if (this.isExcel()) {
      const csv_content = await excel_parser.parseHeaders(file, header_row);
      const headers = await csv_parser.parseHeaders(csv_content, header_row);

      return headers;
    }

    if (this.isCsv()) {
      const headers = await csv_parser.parseHeaders(file, header_row);

      return headers;
    }
  }

  isExcel() {
    return ["xlsx", "xls"].includes(this.extension);
  }

  isCsv() {
    return this.extension === "csv";
  }
}

export default DatafileParser;
