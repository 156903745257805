import axios from "axios";

class FileUploader {
  constructor(accountId, uploader_target) {
    this.accountId = accountId;
    this.uploader = uploader_target
  }

  accountUploadEndpoint() {
    return `/accounts/${this.accountId}/storage_files`;
  }

  openFileDialog() {
    this.uploader.click();
  }

  setUploaderText(text) {
    this.uploader.previousElementSibling.innerHTML = text
  }

  async upload(fileObject, endpoint) {
    const path = endpoint || this.accountUploadEndpoint();
    const data = new FormData();
    data.append("file", fileObject);

    const response = await axios.post(path, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response;
  }
}

export default FileUploader;
