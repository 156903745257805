import Papa from 'papaparse';

class CsvParser {
  constructor() {
    this.parser = Papa;
  }

  parseHeaders(file, header_row) {
    return new Promise(resolve => {
      this.parser.parse(file, {
        delimiter: ",",
        preview: header_row,
        complete: results => {
          resolve(results.data[header_row - 1])
        }
      })
    });
  }
}

export default CsvParser;
