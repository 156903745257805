class IdInputFactory {
  constructor(integrationType) {
    this.integrationType = integrationType;
  }

  create(value, index) {
    const field = document.createElement("input");

    field.setAttribute("type", "hidden");
    field.setAttribute("value", value);
    field.setAttribute(
      "name",
      `${this.integrationType}[field_mappings_attributes][${index}][id]`
    );

    return field;
  }
}

export default IdInputFactory;
