import axios from "axios";

const createDatafile = (accountId, integrationId, marketplaceId, sellerId,
path, eventDate) => {
  const url = `/accounts/${accountId}/integrations/asc/${integrationId}/datafiles`;

  const params = {
    marketplace_id: marketplaceId,
    seller_id: sellerId,
    path: path,
    event_date: eventDate
  }

  return axios.post(url, params);
}

export default { createDatafile };
