class SourceNameInputFactory {
  constructor(integrationType) {
    this.integrationType = integrationType;
  }

  create(value, index) {
    const field = document.createElement("input");

    const fieldName = `${this.integrationType}[field_mappings_attributes][${index}][source_name]`;

    field.setAttribute("type", "hidden");
    field.setAttribute("name", fieldName);
    field.setAttribute("value", value);
    field.setAttribute("class", "form-control is-valid hidden");
    field.setAttribute("data-target", "field-mapping-list-item.sourceField");

    return field;
  }
}

export default SourceNameInputFactory;
